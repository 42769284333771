<template>
  <vue-profile />
</template>

<script>

import Tabs from '@/components/users/Tabs.vue'

export default {
  name: 'ViewProfile',
  components: {
    'vue-profile': Tabs,
  },
}
</script>
