var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-tabs',{attrs:{"card":"","vertical":"","pills":"","content-class":"col-12 col-md-9 mt-1 mt-md-0","nav-wrapper-class":"col-md-3 col-12","nav-class":"nav-left navbar-light"},model:{value:(_vm.tabIndex),callback:function ($$v) {_vm.tabIndex=$$v},expression:"tabIndex"}},[(_vm.$can('read', 'dashboard'))?_c('b-tab',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"UserIcon","size":"18"}}),_c('span',{staticClass:"font-weight-bold"},[_vm._v("Dashboard")])]},proxy:true}],null,false,3551383452)}):_vm._e(),(_vm.$can('read', 'profile'))?_c('b-tab',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"UserIcon","size":"18"}}),_c('span',{staticClass:"font-weight-bold"},[_vm._v("Perfil")])]},proxy:true}],null,false,636330428)},[(_vm.tabIndex == 1)?_c('vue-profile-component'):_vm._e()],1):_vm._e(),(_vm.$can('edit', 'user'))?_c('b-tab',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"LockIcon","size":"18"}}),_c('span',{staticClass:"font-weight-bold"},[_vm._v("Cambiar contraseña")])]},proxy:true}],null,false,4116683126)},[(_vm.tabIndex == 2)?_c('vue-password'):_vm._e()],1):_vm._e(),(_vm.$can('read', 'ticket_purchase_history') &&
      _vm.$can('edit', 'ticket_purchase_history') &&
      _vm.$can('create', 'ticket_purchase_history') &&
      _vm.$can('delete', 'ticket_purchase_history') )?_c('b-tab',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"NavigationIcon","size":"18"}}),_c('span',{staticClass:"font-weight-bold"},[_vm._v("Viajes")])]},proxy:true}],null,false,3465546817)},[(_vm.tabIndex == 3)?_c('vue-travel'):_vm._e()],1):_vm._e(),(_vm.$can('read', 'users') &&
      _vm.$can('edit', 'users') &&
      _vm.$can('create', 'users') &&
      _vm.$can('delete', 'users'))?_c('b-tab',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"UserIcon","size":"18"}}),_c('span',{staticClass:"font-weight-bold"},[_vm._v("Usuarios")])]},proxy:true}],null,false,254267661)}):_vm._e(),(_vm.$can('read', 'company') &&
      _vm.$can('edit', 'company') &&
      _vm.$can('create', 'company') &&
      _vm.$can('delete', 'company') )?_c('b-tab',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"ServerIcon","size":"18"}}),_c('span',{staticClass:"font-weight-bold"},[_vm._v("Compañias")])]},proxy:true}],null,false,690072438)},[(_vm.tabIndex == 4)?_c('vue-company'):_vm._e()],1):_vm._e(),(_vm.$can('read', 'stationbus') &&
      _vm.$can('edit', 'stationbus') &&
      _vm.$can('create', 'stationbus') &&
      _vm.$can('delete', 'stationbus'))?_c('b-tab',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"MapIcon","size":"18"}}),_c('span',{staticClass:"font-weight-bold"},[_vm._v("Terminales")])]},proxy:true}],null,false,727613319)},[(_vm.tabIndex == 5)?_c('vue-bus'):_vm._e()],1):_vm._e(),(_vm.$can('read', 'type_document') &&
      _vm.$can('edit', 'type_document') &&
      _vm.$can('create', 'type_document') &&
      _vm.$can('delete', 'type_document'))?_c('b-tab',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"ArchiveIcon","size":"18"}}),_c('span',{staticClass:"font-weight-bold"},[_vm._v("Tipos de documentos")])]},proxy:true}],null,false,2990910184)},[(_vm.tabIndex == 6)?_c('vue-type-document'):_vm._e()],1):_vm._e(),(_vm.$can('read', 'type_payment') &&
      _vm.$can('edit', 'type_payment') &&
      _vm.$can('create', 'type_payment') &&
      _vm.$can('delete', 'type_payment'))?_c('b-tab',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"CreditCardIcon","size":"18"}}),_c('span',{staticClass:"font-weight-bold"},[_vm._v("Tipos de pagos")])]},proxy:true}],null,false,1008111370)},[(_vm.tabIndex == 7)?_c('vue-type-payment'):_vm._e()],1):_vm._e(),(_vm.$can('read', 'company') &&
      _vm.$can('edit', 'company') &&
      _vm.$can('create', 'company') &&
      _vm.$can('delete', 'company'))?_c('b-tab',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"FlagIcon","size":"18"}}),_c('span',{staticClass:"font-weight-bold"},[_vm._v("Políticas de viajes")])]},proxy:true}],null,false,720563924)},[(_vm.tabIndex == 8)?_c('vue-policy'):_vm._e()],1):_vm._e(),(_vm.$can('read', 'payment_transaction_history'))?_c('b-tab',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"ServerIcon","size":"18"}}),_c('span',{staticClass:"font-weight-bold"},[_vm._v("Historial de compras")])]},proxy:true}],null,false,3727698819)},[(_vm.tabIndex == 9)?_c('vue-history-travel'):_vm._e()],1):_vm._e(),(_vm.$can('read', 'report') && false)?_c('b-tab',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"ServerIcon","size":"18"}}),_c('span',{staticClass:"font-weight-bold"},[_vm._v("Reportes")])]},proxy:true}],null,false,1932351156)}):_vm._e(),(_vm.$can('read', 'roles') &&
      _vm.$can('edit', 'roles') &&
      _vm.$can('create', 'roles') &&
      _vm.$can('delete', 'roles') && false)?_c('b-tab',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"UserIcon","size":"18"}}),_c('span',{staticClass:"font-weight-bold"},[_vm._v("Roles y permisos")])]},proxy:true}],null,false,2727407114)}):_vm._e(),(_vm.$can('read', 'config') && false)?_c('b-tab',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"ServerIcon","size":"18"}}),_c('span',{staticClass:"font-weight-bold"},[_vm._v("Configuración")])]},proxy:true}],null,false,3871611991)}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }