import Status from '@/libs/models/Status'
import TypePayment from '@/libs/models/TypePayment'
import Company from '@/libs/models/Company'

const filterOptionsStatus = async () => {
  try {
    const response = await Status.get()
    return response.data
  } catch (err) {
    // Handle Error Here
    console.error(err)
    return err
  }
}

const filterOptionsTypePayment = async () => {
  try {
    const response = await TypePayment.get()
    return response.data
  } catch (err) {
    // Handle Error Here
    console.error(err)
    return err
  }
}

const filterOptionsCompany = async () => {
  try {
    const response = await Company.get()
    return response.data
  } catch (err) {
    console.error(err)
    return err
  }
}

const formatPrice = (price) => {
  return '$' + price
}

async function columnsValue() {
  const columns = [
    {
      label: 'id transaction',
      field: 'id_transaction',
      tooltip: 'Id interno para las transacciones',
      sortable: true,
      width: '250px',
      filterOptions: {
        customFilter: false,
        trigger: 'enter',
        enabled: false,
        placeholder: 'Buscar...',
      },
    },
    {
      label: 'Referencia',
      field: 'reference',
      tooltip: 'Id interno para las transacciones',
      sortable: false,
      width: '250px',
      filterOptions: {
        customFilter: false,
        trigger: 'enter',
        enabled: false,
        placeholder: 'Buscar...',
      },
    },
    {
      label: 'Estado',
      field: 'status.id',
      type: 'edit-status',
      tdClass: 'text-center',
      sortable: false,
      tooltip: 'Estado actual de la transacción',
      width: '190px',
      edit: true,
      status_id: null,
      filterOptions: {
        enabled: false,
        customFilter: false,
        placeholder: 'Selecciona',
        filterDropdownItems: await filterOptionsStatus(),
      },
    },
    {
      label: 'Tipo de pago',
      field: 'payment_method',
      tooltip: 'Tipo de pago realizado',
      sortable: false,
      width: '190px',
      filterOptions: {
        enabled: false,
        customFilter: false,
        placeholder: 'Selecciona',
        filterDropdownItems: await filterOptionsTypePayment(),
      },
    },
    {
      label: 'Total',
      field: 'amount_in_cents',
      tooltip: 'Total a pagar',
      sortable: false,
      width: '190px',
      formatFn: formatPrice,
    },
    {
      label: 'Fecha de creación',
      field: 'created_at',
      tooltip: 'Tipo de pago realizado',
      sortable: false,
      width: '190px',
    },
  ]
  return columns
}

export { columnsValue, filterOptionsStatus }
