import Model from './Models'

export default class Company extends Model {
  // eslint-disable-next-line
  resource() {
    return 'v1/companies'
  }
  // eslint-disable-next-line
  formData() {
    return {
      indices: true,
    }
  }
}
