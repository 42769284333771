<template>
  <b-modal
    v-model="modalShow"
    v-b-modal.modal-primary
    cancel-variant="outline-secondary"
    ok-title="Login"
    no-close-on-esc
    no-close-on-backdrop
    centered
    header-bg-variant="primary"
    size="lg"
  >
    <template #modal-header>
      <h5 class="text-white">
        {{ title }}
      </h5>
    </template>
    <b-container fluid>
      <b-row>
        <b-col
          cols="12"
        >
          <validation-observer
            ref="TypePaymentForm"
          >
            <b-form @submit.prevent>
              <vue-errors
                v-if="isNotifmsg"
                :notifmsg="notifmsg"
              />
              <b-row>
                <b-col
                  cols="12"
                >
                  <div
                    class="text-center"
                    @click="ActivateFormFile"
                  >
                    <h6>Foto de empresa</h6>
                    <b-img
                      thumbnail
                      fluid
                      width="200"
                      :src="getFilePath"
                    />
                    <h6>Presiona la foto para cambiarla</h6>
                  </div>
                  <b-form-group
                    label-for="companies-photo"
                  >
                    <validation-provider
                      #default="{ errors }"
                      rules="ext:jpg,jpeg,png"
                      name="photo"
                    >
                      <b-form-file
                        id="fileInput"
                        ref="file-input"
                        v-model="photo"
                        class="mb-2"
                        placeholder="Selecciona un archivo"
                        style="visibility: hidden;position: absolute;top: 0;left: -5000px;"
                        accept="image/jpeg, image/png, image/jpeg"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  sm="6"
                >
                  <b-form-group
                    label="Nombre"
                    label-for="typepayment-name"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Nombre"
                      vid="Nombre"
                      rules="required"
                    >
                      <b-form-input
                        id="CompanyForm-name"
                        v-model="name"
                        name="CompanyForm-name"
                        placeholder="Nombre de la compañia"
                        :disabled="!$can('edit', 'type_payment') || !$can('create', 'type_payment')"
                        :state="errors.length > 0 ? false:null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  sm="6"
                >
                  <b-form-group
                    label="Descripción"
                    label-for="typepayment-description"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Descripción"
                      vid="Descripción"
                      rules=""
                    >
                      <b-form-input
                        id="typepayment-description"
                        v-model="description"
                        name="typepayment-name"
                        placeholder="Una breve descipción"
                        :disabled="!$can('edit', 'type_payment') || !$can('create', 'type_payment')"
                        :state="errors.length > 0 ? false:null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  sm="6"
                >
                  <b-form-group
                    label="Estado"
                    label-for="typepayment-status_id"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Estado"
                      rules="required"
                    >
                      <b-form-select
                        v-model="status_id"
                        :state="errors.length > 0 ? false : null"
                        :disabled="!$can('edit', 'type_payment') || !$can('create', 'type_payment')"
                        :options="optionsStatus"
                      >
                        <template #first>
                          <b-form-select-option :value="null">
                            Selecciona una opción
                          </b-form-select-option>
                        </template>
                      </b-form-select>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                >
                  <b-form-group
                    label="Coste por transacción en porcentaje"
                    label-for="typepayment-transactionfee"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="transactionfee"
                      vid="transactionfee"
                      rules=""
                    >
                      <cleave
                        id="typepayment-transactionfee"
                        v-model="transaction_fee"
                        class="form-control"
                        :raw="true"
                        :options="number"
                        name="typepayment-transactionfee"
                        placeholder="Escribe el porcentaje de cobro"
                        :disabled="!$can('edit', 'type_payment') || !$can('create', 'type_payment')"
                        :state="errors.length > 0 ? false:null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                >
                  <b-form-group
                    label="JSON de configuración de pago"
                    label-for="typepayment-data_of_payment"
                  >
                    <b-form-textarea
                      id="textarea-default"
                      v-model="data_of_payment"
                      placeholder="Json"
                      rows="5"
                      :disabled="!$can('edit', 'type_payment') || !$can('create', 'type_payment')"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
            </b-form>
          </validation-observer>
        </b-col>
      </b-row>
    </b-container>
    <template #modal-footer>
      <div class="w-100">
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          class="float-right ml-1"
          @click="save()"
        >
          Enviar
        </b-button>
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="outline-secondary"
          class="float-right"
          @click="close()"
        >
          Cerrar
        </b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  required,
} from '@validations'
import {
  BRow, BCol, BFormGroup, BFormInput, BForm, BButton, BContainer, BFormSelect, BFormSelectOption, BFormTextarea, BFormFile, BImg, BModal,
} from 'bootstrap-vue'
import AlertErrors from '@/components/Errors/Alert.vue'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Status from '@/libs/models/Status'
import TypePayment from '@/libs/models/TypePayment'
import Cleave from 'vue-cleave-component'

export default {
  components: {
    BContainer,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BButton,
    BFormSelect,
    BFormSelectOption,
    BFormTextarea,
    BFormFile,
    BImg,
    BModal,
    ValidationProvider,
    ValidationObserver,
    Cleave,
    'vue-errors': AlertErrors,
  },
  directives: {
    Ripple,
  },
  props: {
    modalShow: {
      type: Boolean,
      required: true,
      default: () => false,
    },
    infoData: {
      type: Object,
      required: false,
      default: () => null,
    },
  },
  data() {
    return {
      // data
      name: null,
      description: null,
      status_id: null,
      data_of_payment: {},
      photo: null,
      transaction_fee: null,

      optionsStatus: [],
      id: null,
      value: [],
      notifmsg: null,
      isNotifmsg: false,
      getFilePath: `${process.env.VUE_APP_URL_STORAGE}/payment/default.png`,
      number: {
        numeral: true,
        numeralThousandsGroupStyle: 'thousand',
      },
      // validation rules
      required,
    }
  },
  computed: {
    title() {
      return Object.keys(this.infoData).length > 0 ? 'Editar Tipo de pago' : 'Crear Tipo de pago'
    },
  },
  watch: {
    photo(value) {
      this.getFilePath = URL.createObjectURL(value)
      if (value && value !== 'payment/default.png' && Object.keys(this.infoData).length > 0) {
        this.updatePhoto()
      }
    },
  },
  created() {
    if (Object.keys(this.infoData).length > 0) {
      this.getFilePath = `${process.env.VUE_APP_URL_STORAGE}${this.infoData.photo}`
      this.name = this.infoData.name
      this.description = this.infoData.description
      this.status_id = this.infoData.status_id
      this.data_of_payment = this.infoData.data_of_payment
      this.id = this.infoData.id
      this.transaction_fee = this.infoData.transaction_fee
    }
  },
  async mounted() {
    this.getOptionsStatus()
  },
  methods: {
    ActivateFormFile() {
      document.getElementById('fileInput').click()
    },
    close() {
      this.$emit('close')
    },
    async getOptionsStatus() {
      const { data } = await Status.whereIn('id', [1, 2, 3, 4]).get()
      this.optionsStatus = data
    },
    async update() {
      const model = await TypePayment.find(this.id)
      // eslint-disable-next-line
      model.name = this.name
      model.description = this.description
      model.status_id = this.status_id
      model.data_of_payment = this.data_of_payment
      model.transaction_fee = this.transaction_fee
      model.save().then(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Se ha actualizado satisfactoriamente',
            icon: 'CheckCircleIcon',
            variant: 'success',
            text: 'El registro se ha actualizado.',
          },
        },
        {
          position: 'top-center',
        })
        this.$emit('edit-result')
        this.close()
      }).catch(error => {
        this.isNotifmsg = true
        this.notifmsg = error.response.data.errors
      })
    },
    create() {
      const company = new TypePayment({
        photo: this.photo,
        name: this.name,
        description: this.description,
        status_id: this.status_id,
        data_of_payment: this.data_of_payment,
        transaction_fee: this.transaction_fee
      })
      company.save().then(res => {//eslint-disable-line
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Se ha creado satisfactoriamente',
            icon: 'CheckCircleIcon',
            variant: 'success',
            text: 'El registro se ha guardado.',
          },
        },
        {
          position: 'top-center',
        })
        this.isNotifmsg = false
        this.notifmsg = []
        this.$emit('create-result')
        this.close()
      }).catch(error => {
        this.isNotifmsg = true
        this.notifmsg = error.response.data.errors
      })
    },
    updatePhoto() {
      const data = new FormData()
      data.append('photo', this.photo)
      this.$http.post(`/v1/type-payment/${this.id}/photo/`, data).then(res => {//eslint-disable-line
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Foto',
            icon: 'CheckCircleIcon',
            variant: 'success',
            text: 'Se ha actualizado correctamente.',
          },
        },
        {
          position: 'top-center',
        })
        this.$emit('edit-result')
      }).catch(error => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error',
            icon: 'XIcon',
            variant: 'danger',
            text: error.response.data.message,
          },
        },
        {
          position: 'bottom-right',
        })
      })
    },
    save() {
      this.$refs.TypePaymentForm.validate().then(async success => {
        if (success) {
          this.isNotifmsg = false
          this.notifmsg = []
          if (Object.keys(this.infoData).length > 0) {
            this.update()
          } else {
            this.create()
          }
        }
      })
    },
  },
}
</script>
<style>
.vue-treeselect__multi-value {
  max-height: calc(12vh) !important;
  overflow: auto;
}

.vue-treeselect__multi-value-label {
  font-size: 10px;
  padding: 1px;
}
.vue-treeselect__menu {
  margin-bottom: 20px;
  max-height: calc(74vh - 18rem) !important;
}
</style>
