var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{directives:[{name:"b-modal",rawName:"v-b-modal.modal-primary",modifiers:{"modal-primary":true}}],attrs:{"cancel-variant":"outline-secondary","ok-title":"Login","no-close-on-esc":"","no-close-on-backdrop":"","centered":"","header-bg-variant":"primary","size":"lg"},scopedSlots:_vm._u([{key:"modal-header",fn:function(){return [_c('h5',{staticClass:"text-white"},[_vm._v(" "+_vm._s(_vm.title)+" ")])]},proxy:true},{key:"modal-footer",fn:function(){return [_c('div',{staticClass:"w-100"},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"float-right ml-1",attrs:{"variant":"primary"},on:{"click":function($event){return _vm.save()}}},[_vm._v(" Enviar ")]),_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"float-right",attrs:{"variant":"outline-secondary"},on:{"click":function($event){return _vm.close()}}},[_vm._v(" Cerrar ")])],1)]},proxy:true}]),model:{value:(_vm.modalShow),callback:function ($$v) {_vm.modalShow=$$v},expression:"modalShow"}},[_c('b-container',{attrs:{"fluid":""}},[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('validation-observer',{ref:"TypeDocumentForm"},[_c('b-form',{on:{"submit":function($event){$event.preventDefault();}}},[(_vm.isNotifmsg)?_c('vue-errors',{attrs:{"notifmsg":_vm.notifmsg}}):_vm._e(),_c('b-row',[_c('b-col',{attrs:{"cols":"12","sm":"6"}},[_c('b-form-group',{attrs:{"label":"Nombre","label-for":"typedocument-name"}},[_c('validation-provider',{attrs:{"name":"Nombre","vid":"Nombre","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"CompanyForm-name","name":"CompanyForm-name","placeholder":"Nombre de la compañia","disabled":!_vm.$can('edit', 'type_document') || !_vm.$can('create', 'type_document'),"state":errors.length > 0 ? false:null},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","sm":"6"}},[_c('b-form-group',{attrs:{"label":"Descripción","label-for":"typedocument-description"}},[_c('validation-provider',{attrs:{"name":"Descripción","vid":"Descripción","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"typedocument-description","name":"typedocument-name","placeholder":"Una breve descipción","disabled":!_vm.$can('edit', 'type_document') || !_vm.$can('create', 'type_document'),"state":errors.length > 0 ? false:null},model:{value:(_vm.description),callback:function ($$v) {_vm.description=$$v},expression:"description"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","sm":"6"}},[_c('b-form-group',{attrs:{"label":"Estado","label-for":"typedocument-status_id"}},[_c('validation-provider',{attrs:{"name":"Estado","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"state":errors.length > 0 ? false : null,"disabled":!_vm.$can('edit', 'type_document') || !_vm.$can('create', 'type_document'),"options":_vm.optionsStatus},scopedSlots:_vm._u([{key:"first",fn:function(){return [_c('b-form-select-option',{attrs:{"value":null}},[_vm._v(" Selecciona una opción ")])]},proxy:true}],null,true),model:{value:(_vm.status_id),callback:function ($$v) {_vm.status_id=$$v},expression:"status_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","sm":"6"}},[_c('b-form-group',{attrs:{"label":"Empresa","label-for":"StationBusForm-company_id"}},[_c('validation-provider',{attrs:{"name":"Empresa","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"label":"name","options":_vm.optionsCompany,"loading":_vm.loadingCompany,"clearable":true,"placeholder":"Empresa","disabled":!_vm.$can('edit', 'type_document') || !_vm.$can('create', 'type_document')},scopedSlots:_vm._u([{key:"no-options",fn:function(ref){return [_vm._v(" Sin datos. ")]}},{key:"option",fn:function(option){return [_c('span',{class:option.icon}),_vm._v(" "+_vm._s(option.name)+" ")]}}],null,true),model:{value:(_vm.company_id),callback:function ($$v) {_vm.company_id=$$v},expression:"company_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","sm":"6"}},[_c('b-form-group',{attrs:{"label":"Tipo de documento soap","label-for":"typedocument-soap"}},[_c('validation-provider',{attrs:{"name":"Tipo documento soap","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"get-option-label":function (city) { return city['@attributes'].Codigo; },"options":_vm.rowTypeDocuments,"loading":_vm.loading,"clearable":true,"placeholder":"Tipo de documento soap","disabled":!_vm.$can('edit', 'type_document') || !_vm.$can('create', 'type_document')},scopedSlots:_vm._u([{key:"no-options",fn:function(ref){return [_vm._v(" Sin datos. ")]}},{key:"option",fn:function(option){return [_c('span',{class:option.icon}),_vm._v(" "+_vm._s(option['@attributes'].Codigo)+" ")]}}],null,true),model:{value:(_vm.homolog_id),callback:function ($$v) {_vm.homolog_id=$$v},expression:"homolog_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }