<template>
  <b-modal
    v-model="modalShow"
    v-b-modal.modal-primary
    cancel-variant="outline-secondary"
    ok-title="Login"
    no-close-on-esc
    no-close-on-backdrop
    centered
    header-bg-variant="primary"
    size="xl"
  >
    <template #modal-header>
      <h5 class="text-white">
        {{ title }}
      </h5>
    </template>
    <b-container fluid>
      <b-row>
        <b-col
          cols="12"
        >
          <b-table-lite
            v-if="travelData"
            responsive
            :items="travelData.chairs_select"
            :fields="fields"
          />
        </b-col>
      </b-row>
    </b-container>
    <template #modal-footer>
      <div class="w-100">
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="outline-secondary"
          class="float-right"
          @click="close()"
        >
          Cerrar
        </b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
import {
  BRow, BCol, BButton, BContainer, BTableLite,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BContainer,
    BRow,
    BCol,
    BTableLite,
    BButton
  },
  directives: {
    Ripple,
  },
  props: {
    modalShow: {
      type: Boolean,
      required: true,
      default: () => false,
    },
    travelData: {
      type: Object,
      required: false,
      default: () => null,
    },
  },
  data() {
    return {
      fields: [
        {
          key: 'first_name', label: 'Nombres',
        },
        {
          key: 'last_name', label: 'Apellidos',
        },
        {
          key: 'document', label: 'Documento',
        },
        {
          key: 'label', label: 'N Silla',
        },
        {
          key: 'importe_base',
          label: 'Precio',
          formatter: value => `$ ${this.formatPrice(value)}`,
        },
      ],
    }
  },
  computed: {
    title() {
      return 'Información sobre las sillas'
    },
  },
  methods: {
    formatPrice(price) {
      return new Intl.NumberFormat('de-DE',
        {
          currency: 'COP',
        }).format(price)
    },
    close() {
      this.$emit('close')
    },
  },
}
</script>
