<template>
  <div>
    <b-card title="Perfil">
      <b-container>
        <b-row class="justify-content-md-center">
          <b-col cols="12">
            <validation-observer
              ref="profileForm"
            >
              <b-form
                @submit.prevent="onSubmit"
              >
                <b-row>
                  <b-col
                    cols="12"
                    sm="6"
                  >
                    <b-form-group
                      label-for="Nombres"
                      label="Nombres"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Nombres"
                        rules="required"
                      >
                        <b-form-input
                          id="FirstName"
                          v-model="first_name"
                          name="login-FirstName"
                          :state="errors.length > 0 ? false:null"
                          autofocus
                          :disabled="!$can('edit', 'profile')"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col
                    cols="12"
                    sm="6"
                  >
                    <b-form-group
                      label-for="Apellidos"
                      label="Apellidos"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Apellidos"
                        rules="required"
                      >
                        <b-form-input
                          v-model="last_name"
                          name="login-LastName"
                          :state="errors.length > 0 ? false:null"
                          autofocus
                          :disabled="!$can('edit', 'profile')"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col
                    cols="12"
                    sm="6"
                  >
                    <b-form-group
                      label="Email"
                      label-for="users-email"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Email"
                        vid="email"
                        rules="required|email"
                        immediate
                      >
                        <b-form-input
                          id="users-email"
                          v-model="emailUsers"
                          placeholder="Email"
                          :state="errors.length > 0 ? false : null"
                          name="users-email"
                          :disabled="!$can('edit', 'user')"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col
                    cols="12"
                    sm="6"
                  >
                    <b-form-group
                      label="Genero"
                      label-for="users-genero"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Genero"
                        rules="required"
                      >
                        <b-form-select
                          v-model="gender"
                          :state="errors.length > 0 ? false : null"
                          :disabled="!$can('edit', 'profile')"
                          :options="optionsGender"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col
                    cols="12"
                    sm="6"
                  >
                    <b-form-group
                      label="Tipo de documento"
                      label-for="users-email"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Tipo de documento"
                        rules="required"
                      >
                        <b-form-select
                          v-model="type_document"
                          placeholder="Selecciona"
                          :state="errors.length > 0 ? false : null"
                          :disabled="!$can('edit', 'profile')"
                          :options="optionsTypeDocument"
                        >
                          <b-form-select-option :value="null">
                            Selecciona
                          </b-form-select-option>
                        </b-form-select>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col
                    cols="12"
                    sm="6"
                  >
                    <b-form-group
                      label-for="Número de documento"
                      label="Número de documento"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Número de documento"
                        rules="required"
                      >
                        <b-form-input
                          v-model="document"
                          name="document"
                          :state="errors.length > 0 ? false:null"
                          autofocus
                          :disabled="!$can('edit', 'profile')"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col
                    cols="12"
                    sm="6"
                  >
                    <b-form-group
                      label-for="Fecha de nacimiento"
                      label="Fecha de nacimiento"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Fecha de nacimiento"
                        rules="required"
                      >
                        <datepicker
                          v-model="date_of_birth"
                          placeholder="Fecha de nacimiento"
                          :use-utc="true"
                          :bootstrap-styling="true"
                          format="d MMMM yyyy"
                          :state="errors.length > 0 ? false : null"
                          :language="espanish"
                          :disabled="!$can('edit', 'profile')"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col
                    cols="12"
                    sm="6"
                  >
                    <b-form-group
                      label-for="Ciudad"
                      label="Ciudad"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Ciudad"
                        rules="required"
                      >
                        <v-select
                          v-model="city"
                          name="city"
                          label="name"
                          :filterable="false"
                          :options="rowCity"
                          placeholder="Seleccione una opción"
                          :disabled="!$can('edit', 'profile')"
                          @search="onSearch"
                        >
                          <template #option="{ name }">
                            <h6>{{ capitalize(name) }}</h6>
                          </template>
                          <template #selected-option="{ name }">
                            <div style="display: flex; align-items: baseline">
                              {{ capitalize(name) }}
                            </div>
                          </template>
                          <template v-slot:no-options="{ search, searching }">
                            <template v-if="searching">
                              No existe la ciudad <em>{{ search }}</em>.
                            </template>
                            <em
                              v-else
                              style="opacity: 0.5"
                            >Comience a escribir para buscar una ciudad.</em>
                          </template>
                        </v-select>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col
                    cols="12"
                    sm="6"
                  >
                    <b-form-group
                      label-for="Teléfono"
                      label="Teléfono"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Teléfono"
                        rules="required"
                      >
                        <b-input-group>
                          <b-input-group-prepend is-text>
                            🇨🇴 (+57)
                          </b-input-group-prepend>
                          <cleave
                            id="phone"
                            v-model="phone_number"
                            class="form-control"
                            :raw="false"
                            :options="phone"
                            placeholder="1234 567 8900"
                          />
                        </b-input-group>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  block
                  type="submit"
                  :disabled="isLoading"
                >
                  <b-spinner
                    v-if="isLoading"
                    small
                    type="grow"
                  />
                  <span class="align-middle">Actualizar Perfil</span>
                </b-button>
              </b-form>
            </validation-observer>
          </b-col>
        </b-row>
      </b-container>
    </b-card>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BRow, BCol, BFormGroup, BFormInput, BForm, BContainer, BCard, BFormSelect,
  BButton, BSpinner, BInputGroup, BInputGroupPrepend, BFormSelectOption,
} from 'bootstrap-vue'
import Cleave from 'vue-cleave-component'
import 'cleave.js/dist/addons/cleave-phone.us'
import Ripple from 'vue-ripple-directive'
import Datepicker from 'vuejs-datepicker'
import { es } from 'vuejs-datepicker/dist/locale'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store'

export default {
  name: 'ProfileComponent',
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BContainer,
    BCard,
    BFormSelect,
    BButton,
    BSpinner,
    BInputGroup,
    BInputGroupPrepend,
    BFormSelectOption,
    ValidationProvider,
    ValidationObserver,
    vSelect,
    Cleave,
    Datepicker,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      first_name: null,
      last_name: null,
      emailUsers: null,
      type_document: null,
      document: null,
      gender: null,
      phone_number: null,
      date_of_birth: null,
      city: null,

      isLoading: false,
      espanish: es,
      phone: {
        phone: true,
        phoneRegionCode: 'US',
      },
      optionsTypeDocument: null,
      optionsGender: [
        { value: null, text: 'Selecciona una opción' },
        { value: 'Male', text: 'Hombre' },
        { value: 'Female', text: 'Mujer' },
      ],
      rowCity: [],
    }
  },
  mounted() {
    this.getTypeDocument()
    this.getUserData()
  },
  methods: {
    capitalize(word) {
      return word[0].toUpperCase() + word.slice(1).toLowerCase()
    },
    getUserData() {
      const { id } = JSON.parse(localStorage.getItem('userData'))
      this.$http.get(`/v1/users/${id}`).then(res => {
        this.first_name = res.data.data.first_name
        this.last_name = res.data.data.last_name
        this.emailUsers = res.data.data.email
        this.type_document = res.data.data.type_document
        this.document = res.data.data.document
        this.gender = res.data.data.gender
        this.phone_number = res.data.data.phone_number
        this.date_of_birth = res.data.data.date_of_birth
        this.city = res.data.data.city
      })
    },
    onSearch(search, loading) {
      if (search.length) {
        loading(true)
        this.getCity(loading, search.toLowerCase())
      }
    },
    getCity(loading, search) {
      this.$http.get('/v1/city', {
        params: {
          search,
        },
      }).then(res => {
        this.rowCity = res.data.data
        loading(false)
      })
    },
    getTypeDocument() {
      this.$http.get('/v1/type-document', {
        params: {
          company_id: JSON.parse(localStorage.getItem('userData')).company != null ? JSON.parse(localStorage.getItem('userData')).company.id : null,
        },
      }).then(res => {
        this.optionsTypeDocument = res.data.data
      })
    },
    onSubmit() {
      this.$refs.profileForm.validate().then(async success => {
        if (success) {
          this.isLoading = true
          const { id } = JSON.parse(localStorage.getItem('userData'))
          this.$http.put(`/v1/users/${id}`, {
            name: this.first_name,
            first_name: this.first_name,
            last_name: this.last_name,
            email: this.emailUsers,
            type_document: this.type_document,
            document: this.document,
            gender: this.gender,
            phone_number: this.phone_number.split(' ').join(''),
            date_of_birth: this.date_of_birth,
            city: this.city.id,
          }).then(res => {
            this.isLoading = false
            localStorage.removeItem('userData')
            localStorage.setItem('userData', JSON.stringify(res.data.data))
            store.dispatch('userApp/setProfileData', res.data.data)
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Mensaje',
                icon: 'CheckIcon',
                variant: 'success',
                text: 'Actualizado correctamente',
              },
            },
            {
              position: 'top-center',
            })
            this.$emit('resgisterCompleted')
          }).catch(error => {
            this.isLoading = false
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Mensaje',
                icon: 'XIcon',
                variant: 'danger',
                text: error.response.data.message,
              },
            },
            {
              position: 'top-center',
            })
          })
        }
      })
    },
  },
}

</script>
