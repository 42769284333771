<template>
  <b-modal
    v-model="modalShow"
    v-b-modal.modal-primary
    cancel-variant="outline-secondary"
    ok-title="Login"
    no-close-on-esc
    no-close-on-backdrop
    centered
    header-bg-variant="primary"
    size="lg"
  >
    <template #modal-header>
      <h5 class="text-white">
        {{ title }}
      </h5>
    </template>
    <b-container fluid>
      <b-row>
        <b-col
          cols="12"
        >
          <validation-observer
            ref="StationBusForm"
          >
            <b-form @submit.prevent>
              <vue-errors
                v-if="isNotifmsg"
                :notifmsg="notifmsg"
              />
              <b-row>
                <b-col
                  cols="12"
                  sm="6"
                >
                  <b-form-group
                    label="Nombre"
                    label-for="typecontract-name"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Nombre"
                      vid="Nombre"
                      rules="required"
                    >
                      <b-form-input
                        id="StationBusForm-name"
                        v-model="name"
                        name="StationBusForm-name"
                        placeholder="Nombre de la terminal"
                        :disabled="!$can('edit', 'status') || !$can('create', 'status')"
                        :state="errors.length > 0 ? false:null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  sm="6"
                >
                  <b-form-group
                    label="Compañia"
                    label-for="StationBusForm-status_id"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Empresa"
                      rules="required"
                    >
                      <v-select
                        v-model="company_id"
                        label="name"
                        :options="optionsCompany"
                        :loading="loadingCompany"
                        :clearable="true"
                        placeholder="Compañia a la que pertenece el terminal"
                        :disabled="!$can('edit', 'company') || !$can('create', 'company')"
                      >
                        <template #no-options="{ }">
                          Sin datos.
                        </template>
                        <template v-slot:option="option">
                          <span :class="option.icon" />
                          {{ option.name }}
                        </template>
                      </v-select>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  sm="6"
                >
                  <b-form-group
                    label-for="Ciudad"
                    label="Ciudad"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Ciudad"
                      rules="required"
                    >
                      <vue-autosuggest
                        ref="autocompleteCityOrigin"
                        v-model="cityQuery"
                        :suggestions="suggestions"
                        :input-props="inputProps"
                        :section-configs="sectionConfigs"
                        :render-suggestion="renderSuggestion"
                        :get-suggestion-value="getSuggestionValue"
                        @input="fetchResults"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  sm="6"
                >
                  <b-form-group
                    label="Terminales"
                    label-for="StationBusForm-status_id"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Terminales"
                      rules="required"
                    >
                      <v-select
                        v-model="code_homolog_soap"
                        :get-option-label="city => city['@attributes'].Nombre"
                        :options="rowHabilitadas"
                        :loading="loading"
                        :clearable="true"
                        placeholder="Terminales habilitadas"
                        :disabled="!$can('edit', 'status') || !$can('create', 'status')"
                      >
                        <template #no-options="{ }">
                          Sin datos.
                        </template>
                        <template v-slot:option="option">
                          <span :class="option.icon" />
                          {{ option['@attributes'].Nombre }}
                        </template>
                      </v-select>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  sm="6"
                >
                  <b-form-group
                    label="Estado"
                    label-for="StationBusForm-status_id"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Estado"
                      rules="required"
                    >
                      <b-form-select
                        v-model="status_id"
                        :state="errors.length > 0 ? false : null"
                        :disabled="!$can('edit', 'status') || !$can('create', 'status')"
                        :options="optionsStatus"
                      >
                        <template #first>
                          <b-form-select-option :value="null">
                            Selecciona una opción
                          </b-form-select-option>
                        </template>
                      </b-form-select>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col
                  v-if="status_id != 1"
                  cols="12"
                  sm="6"
                >
                  <b-form-group
                    label="Motivo"
                    label-for="StationBusForm-motive"
                  >
                    <b-form-textarea
                      id="textarea-default"
                      v-model="motive"
                      placeholder="Escribe el motivo"
                      rows="3"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
            </b-form>
          </validation-observer>
        </b-col>
      </b-row>
    </b-container>
    <template #modal-footer>
      <div class="w-100">
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          class="float-right ml-1"
          @click="save()"
        >
          Enviar
        </b-button>
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="outline-secondary"
          class="float-right"
          @click="close()"
        >
          Cerrar
        </b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  required,
} from '@validations'
import {
  BRow, BCol, BFormGroup, BFormInput, BForm, BButton, BContainer, BFormSelect, BFormSelectOption, BFormTextarea,
} from 'bootstrap-vue'
import AlertErrors from '@/components/Errors/Alert.vue'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import vSelect from 'vue-select'
import Status from '@/libs/models/Status'
import StationBus from '@/libs/models/StationBus'
import Company from '@/libs/models/Company'
import { VueAutosuggest } from 'vue-autosuggest'

export default {
  components: {
    BContainer,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BButton,
    BFormSelect,
    BFormSelectOption,
    BFormTextarea,
    ValidationProvider,
    ValidationObserver,
    'vue-errors': AlertErrors,
    vSelect,
    VueAutosuggest,
  },
  directives: {
    Ripple,
  },
  props: {
    modalShow: {
      type: Boolean,
      required: true,
      default: () => false,
    },
    infoData: {
      type: Object,
      required: false,
      default: () => null,
    },
  },
  data() {
    return {
      // Data
      name: null,
      code_homolog_soap: null,
      company_id: null,
      status_id: null,
      city: null,
      motive: null,
      cityQuery: null,

      id: null,
      value: [],
      notifmsg: null,
      isNotifmsg: false,

      rowHabilitadas: [],
      rowCity: [],
      optionsStatus: [],
      optionsCompany: [],
      loading: false,
      loadingCompany: false,

      // Suggestions
      suggestions: [],
      inputProps: {
        id: 'autosuggest__input_ajax_city_origin',
        placeholder: 'Ciudad',
        class: 'form-control',
        name: 'ajax',
      },
      sectionConfigs: {
        cityTerminales: {
          limit: 5,
          label: 'Ciudad',
          onSelected: selected => {
            this.city = selected.item
          },
        },
      },
      // validation rules
      required,
    }
  },
  computed: {
    title() {
      return this.infoData ? 'Editar Terminales' : 'Crear Terminales'
    },
  },
  watch: {
    company_id() {
      this.terminalesHabilitadas()
    },
  },
  async created() {
    if (this.$can('read', 'company')) {
      this.company_id = JSON.parse(localStorage.getItem('userData')).company
    }
    if (Object.keys(this.infoData).length > 0) {
      this.name = this.infoData.name
      this.status_id = this.infoData.status_id
      this.company_id = this.infoData.company
      if (this.infoData.city) {
        this.city = await this.setCity(this.infoData.city.id)
        this.cityQuery = this.capitalize(this.city.name)
        this.suggestions.push({ name: 'cityTerminales', data: [this.city] })
      }
      this.motive = this.infoData.motive
      this.id = this.infoData.id
    }
  },
  async mounted() {
    this.getOptionsStatus()
    this.getCompanies()
  },
  methods: {
    capitalize(word) {
      return word[0].toUpperCase() + word.slice(1).toLowerCase()
    },
    async setCity(withCity) {
      return this.$http.get('/v1/city', {
        params: {
          with_city: withCity,
        },
      }).then(res => res.data.data[0])
    },
    close() {
      this.$emit('close')
    },
    async getOptionsStatus() {
      const { data } = await Status.whereIn('id', [1, 2, 3, 4]).get()
      this.optionsStatus = data
    },
    async getCompanies() {
      this.loadingCompany = true
      const { data } = await Company.get()
      this.optionsCompany = data
      this.loadingCompany = false
    },
    terminalesHabilitadas() {
      this.loading = true
      this.$http.get('/v1/soap/terminales', {
        params: {
          company_id: this.company_id ? this.company_id.id : null,
        },
      }).then(res => {
        this.rowHabilitadas = res.data
        this.loading = false
        if (Object.keys(this.infoData).length > 0) {
          this.code_homolog_soap = res.data.find(element => element['@attributes'].ID === this.infoData.code_homolog_soap)
        }
      })
    },
    async fetchResults() {
      const { cityQuery } = this
      this.selected = null
      if (cityQuery) {
        this.suggestions = []
      }
      if (cityQuery.length >= 2) {
        const cityOrigin = await this.$http.get('/v1/city', {
          params: {
            search: cityQuery.toLowerCase(),
          },
        }).then(res => res)
        this.suggestions = []
        this.suggestions.push({ name: 'cityTerminales', data: cityOrigin.data.data })
      }
    },
    renderSuggestion(suggestion) {
      return this.capitalize(suggestion.item.name)
    },
    getSuggestionValue(suggestion) {
      const { item } = suggestion
      return this.capitalize(item.name)
    },
    save() {
      this.$refs.StationBusForm.validate().then(async success => {
        if (success) {
          this.isNotifmsg = false
          if (Object.keys(this.infoData).length > 0) {
            const model = await StationBus.find(this.id)
            model.name = this.name
            model.code_homolog_soap = this.code_homolog_soap ? this.code_homolog_soap['@attributes'].ID : null
            model.status_id = this.status_id
            model.company_id = this.company_id ? this.company_id.id : null
            model.city = this.city ? this.city.id : null
            //eslint-disable-line
            if (this.status_id !== 1) {
              model.motive = this.motive
            }
            model.save().then(() => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Se ha actualizado satisfactoriamente',
                  icon: 'CheckCircleIcon',
                  variant: 'success',
                  text: 'El registro se ha actualizado.',
                },
              },
              {
                position: 'top-center',
              })
              this.$emit('edit-result')
              this.close()
            }).catch(error => {
              this.isNotifmsg = true
              this.notifmsg = error.response.data.errors
            })
          } else {
            const model = new StationBus({
              name: this.name,
              code_homolog_soap: this.code_homolog_soap ? this.code_homolog_soap['@attributes'].ID : null,
              status_id: this.status_id,
              company_id: this.company_id ? this.company_id.id : null,
              city: this.city ? this.city.id : null,
            })
            model.save().then(res => {//eslint-disable-line
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Se ha creado satisfactoriamente',
                  icon: 'CheckCircleIcon',
                  variant: 'success',
                  text: 'El registro se ha guardado.',
                },
              },
              {
                position: 'top-center',
              })
              this.$emit('create-result')
              this.close()
            }).catch(error => {
              this.isNotifmsg = true
              this.notifmsg = error.response.data.errors
            })
          }
        }
      })
    },
  },
}
</script>
<style>
.vue-treeselect__multi-value {
  max-height: calc(12vh) !important;
  overflow: auto;
}

.vue-treeselect__multi-value-label {
  font-size: 10px;
  padding: 1px;
}
.vue-treeselect__menu {
  margin-bottom: 20px;
  max-height: calc(74vh - 18rem) !important;
}
</style>
