<template>
  <div>
    <b-card title="Cambiar Contraseña">
      <b-container>
        <b-row class="justify-content-md-center">
          <b-col cols="12">
            <validation-observer
              ref="changePasswordForm"
            >
              <b-form
                @submit.prevent="onSubmit"
              >
                <b-row>
                  <b-col
                    cols="12"
                    sm="6"
                  >
                    <b-form-group
                      label="Email"
                      label-for="password-email"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Email"
                        vid="email"
                        rules="required|email"
                        immediate
                      >
                        <b-form-input
                          id="password-email"
                          v-model="emailUsers"
                          placeholder="Email"
                          :state="errors.length > 0 ? false : null"
                          name="users-email"
                          disabled
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col
                    cols="12"
                    sm="6"
                  >
                    <b-form-group>
                      <div class="d-flex justify-content-between">
                        <label for="password">Nueva Contraseña</label>
                      </div>
                      <validation-provider
                        #default="{ errors }"
                        name="Contraseña"
                        rules="required"
                      >
                        <b-input-group
                          class="input-group-merge"
                          :class="errors.length > 0 ? 'is-invalid':null"
                        >
                          <b-form-input
                            id="password"
                            v-model="password"
                            :type="passwordFieldType"
                            class="form-control-merge"
                            :state="errors.length > 0 ? false:null"
                            name="login-password"
                            placeholder="Nueva Contraseña"
                            autocomplete="new-password"
                            :disabled="!$can('edit', 'user')"
                          />

                          <b-input-group-append is-text>
                            <feather-icon
                              class="cursor-pointer"
                              :icon="passwordToggleIcon"
                              @click="togglePasswordVisibility"
                            />
                          </b-input-group-append>
                        </b-input-group>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  block
                  type="submit"
                  :disabled="isLoading"
                >
                  <b-spinner
                    v-if="isLoading"
                    small
                    type="grow"
                  />
                  <span class="align-middle">Actualizar contraseña</span>
                </b-button>
              </b-form>
            </validation-observer>
          </b-col>
        </b-row>
      </b-container>
    </b-card>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BRow, BCol, BFormGroup, BFormInput, BForm, BContainer, BCard,
  BButton, BSpinner, BInputGroup, BInputGroupAppend,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'

// Agregar el get de ciudades para poder consultarlo en el front
// Terminar el perfil de usuario
// Agregar pestaña Viajes
// Soportar el cambio de contraseña cuando se esta logueado
// Probar la integración para empezar a realizar el api de venta de boletos

export default {
  name: 'ProfileComponent',
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BContainer,
    BCard,
    BButton,
    BSpinner,
    BInputGroup,
    BInputGroupAppend,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      emailUsers: JSON.parse(localStorage.getItem('userData')).email,
      password: null,
      passwordFieldType: 'password',

      isLoading: false,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  methods: {
    onSubmit() {
      this.$refs.changePasswordForm.validate().then(async success => {
        if (success) {
          this.isLoading = true
          this.$http.post('/v1/users/change-password', {
            email: this.emailUsers,
            new_password: this.password,
          }).then(() => {
            this.isLoading = false
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Mensaje',
                icon: 'CheckIcon',
                variant: 'success',
                text: 'Contraseña actualizada',
              },
            },
            {
              position: 'top-center',
            })
          }).catch(error => {
            this.isLoading = false
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Mensaje',
                icon: 'XIcon',
                variant: 'danger',
                text: error.response.data.message,
              },
            },
            {
              position: 'top-center',
            })
          })
        }
      })
    },
  },
}

</script>
