<template>
  <div>
    <b-tabs>
      <b-tab
        title="Políticas de viaje"
        active
      >
        <b-col cols="12">
          <good-table
            ref="gootable"
            :model-data="policy"
            :columns="columns"
            :permissions="{
              create: $can('create', 'company'),
              edit: $can('edit', 'company'),
              show: !$can('read', 'company'),
              delete: $can('delete', 'company'),
            }"
            @Create="Create"
            @Edit="EditRecord"
            @edit-select="editSelect"
            @delete="deleteRecord"
            @selection-changed="selectionChanged"
          />
        </b-col>
      </b-tab>
    </b-tabs>
    <vue-stationbus-form
      v-if="modalShow"
      :modal-show="modalShow"
      :info-data="infoData"
      @close="Create"
      @create-result="$refs.gootable.loadItems()"
      @edit-result="$refs.gootable.loadItems()"
    />
  </div>
</template>
<script>
import {
  BCol, BTabs, BTab,
} from 'bootstrap-vue'
import GoodTable from '@/components/Table/Index.vue'
import Form from '@/components/Policy/Form.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { columnsValue } from '@/components/Policy/field/index'
import Policy from '@/libs/models/Policy'

export default {
  components: {
    BCol,
    BTabs,
    BTab,
    GoodTable,
    'vue-stationbus-form': Form,
  },
  data() {
    return {
      rows: [],
      isLoading: false,
      show: false,
      selectRow: null,
      selecction: [],
      infoData: {},
      searchTerm: null,
      UsersData: {},

      modalShow: false,
      modalImportShow: false,
      columns: [],
      policy: Policy,
    }
  },
  async mounted() {
    this.columns = await columnsValue()
    if (this.$can('delete', 'stationbus') || this.$can('edit', 'stationbus')) {
      this.columns.unshift({
        label: 'Acciones',
        field: 'action',
        sortable: false,
      })
    }
  },
  methods: {
    Create() {
      this.infoData = {}
      this.modalShow = !this.modalShow
    },
    EditRecord(data) {
      this.infoData = data
      this.modalShow = !this.modalShow
    },
    editSelect() {
      if (this.selecction.length > 1 || this.selecction.length === 0) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Solo puedes editar un registro',
            icon: 'XIcon',
            variant: 'warning',
            text: 'al presionar el boton editar, este solo permitira editar un registro',
          },
        },
        {
          position: 'top-center',
        })
      } else {
        this.infoData = this.selecction['0']
        this.modalShow = !this.modalShow
      }
    },
    importData() {
      this.modalImportShow = !this.modalImportShow
    },
    deleteRecord(val) {
      this.$http.delete(`/v1/policies/${val.id}`).then(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Se ha eliminado correctamente',
            icon: 'CheckCircleIcon',
            variant: 'success',
            text: 'El registro se ha eliminado.',
          },
        },
        {
          position: 'top-center',
        })
        this.$refs.gootable.loadItems()
        this.$refs.gootableTrashed.loadItems()
      }).catch(error => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error',
            icon: 'XIcon',
            variant: 'danger',
            text: error.response.data.message,
          },
        },
        {
          position: 'bottom-right',
        })
      })
    },
    selectionChanged(val) {
      this.selecction = val.selectedRows
    },
  },
}
</script>
