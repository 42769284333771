import Status from '@/libs/models/Status'
import TypePayment from '@/libs/models/TypePayment'
import Company from '@/libs/models/Company'

const filterOptionsStatus = async () => {
  try {
    const response = await Status.get()
    return response.data
  } catch (err) {
    // Handle Error Here
    console.error(err)
    return err
  }
}

const filterOptionsTypePayment = async () => {
  try {
    const response = await TypePayment.get()
    return response.data
  } catch (err) {
    // Handle Error Here
    console.error(err)
    return err
  }
}

const filterOptionsCompany = async () => {
  try {
    const response = await Company.get()
    return response.data
  } catch (err) {
    console.error(err)
    return err
  }
}

const formatPrice = (price) => {
  return '$' + new Intl.NumberFormat('de-DE',
  {
    currency: 'COP',
  }).format(price)
}

async function columnsValue() {
  const columns = [
    {
      label: 'ID',
      field: 'id',
      tooltip: 'Id interno para las transacciones',
      sortable: true,
      width: '110px',
      filterOptions: {
        customFilter: false,
        trigger: 'enter',
        enabled: true,
        placeholder: 'Buscar...',
      },
    },
    {
      label: 'Estado',
      field: 'status.id',
      tdClass: 'text-center',
      sortable: false,
      tooltip: 'Estado actual de la transacción',
      width: '190px',
      filterOptions: {
        enabled: true,
        customFilter: false,
        placeholder: 'Todos',
        filterDropdownItems: await filterOptionsStatus(),
      },
    },
    {
      label: 'Email',
      field: 'email',
      tooltip: 'Email del usuario que realizo la transacción',
      sortable: true,
      width: '190px',
      filterOptions: {
        customFilter: false,
        trigger: 'enter',
        enabled: true,
        placeholder: 'Buscar...',
      },
    },
    {
      label: 'Empresa',
      field: 'company.name',
      tooltip: 'Empresa asociada',
      sortable: false,
      width: '190px',
      filterOptions: {
        enabled: true,
        customFilter: false,
        placeholder: 'Todas',
        filterDropdownItems: await filterOptionsCompany(),
      },
    },
    {
      label: 'Origen',
      field: 'source',
      tooltip: 'Origen del viaje',
      sortable: false,
      width: '190px',
      filterOptions: {
        customFilter: false,
        trigger: 'enter',
        enabled: true,
        placeholder: 'Buscar...',
      },
    },
    {
      label: 'Destino',
      field: 'destination',
      tooltip: 'Destino del viaje',
      sortable: false,
      width: '190px',
      filterOptions: {
        customFilter: false,
        trigger: 'enter',
        enabled: true,
        placeholder: 'Buscar...',
      },
    },
    {
      label: 'Fecha de salida',
      field: 'departure_date',
      tooltip: 'Fecha de salida del viaje',
      sortable: false,
      width: '190px',
      filterOptions: {
        customFilter: false,
        trigger: 'enter',
        enabled: true,
        placeholder: 'Buscar...',
      },
    },
    {
      label: 'Fecha de llegada',
      field: 'arrival_date',
      tooltip: 'Fecha de llegada del viaje',
      sortable: false,
      width: '190px',
      filterOptions: {
        customFilter: false,
        trigger: 'enter',
        enabled: true,
        placeholder: 'Buscar...',
      },
    },
    {
      label: 'Fecha de llegada',
      field: 'arrival_date',
      tooltip: 'Fecha de llegada del viaje',
      sortable: false,
      width: '190px',
      filterOptions: {
        customFilter: false,
        trigger: 'enter',
        enabled: true,
        placeholder: 'Buscar...',
      },
    },
    {
      label: 'Transaccion ID',
      field: 'TransaccionID',
      tooltip: 'Id de transacción asociado al api soap de sisorg',
      sortable: false,
      width: '190px',
      filterOptions: {
        customFilter: false,
        trigger: 'enter',
        enabled: true,
        placeholder: 'Buscar...',
      },
    },
    {
      label: 'Viaje ID',
      field: 'viaje_id',
      tooltip: 'Id de viaje asociado al api soap de sisorg',
      sortable: false,
      width: '190px',
      filterOptions: {
        customFilter: false,
        trigger: 'enter',
        enabled: true,
        placeholder: 'Buscar...',
      },
    },
    {
      label: 'Tipo de pago',
      field: 'type_payment.id',
      tooltip: 'Tipo de pago realizado',
      sortable: false,
      width: '190px',
      filterOptions: {
        enabled: true,
        customFilter: false,
        placeholder: 'Selecciona',
        filterDropdownItems: await filterOptionsTypePayment(),
      },
    },
    {
      label: 'Bus',
      field: 'type_bus',
      tooltip: 'Bus',
      sortable: false,
      width: '190px',
      filterOptions: {
        customFilter: false,
        trigger: 'enter',
        enabled: true,
        placeholder: 'Buscar...',
      },
    },
    {
      label: 'Sillas',
      field: 'chairs_select',
      tooltip: 'Sillas compradas',
      sortable: false,
      width: '190px',
    },
    {
      label: 'Total a pagar',
      field: 'total_price',
      tooltip: 'Total a pagar del tiquete',
      sortable: true,
      width: '190px',
      formatFn: formatPrice,
    },
    {
      label: 'Fecha de creación',
      field: 'created_at',
      tooltip: 'Tipo de pago realizado',
      sortable: true,
      width: '190px',
    },
  ]
  return columns
}

export { columnsValue, filterOptionsStatus }
