<template>
  <div>
    <b-overlay
      :show="show"
      rounded="sm"
      variant="white"
      spinner-variant="primary"
      blur="0"
      opacity=".75"
    >
      <b-col cols="12">
        <good-table
          ref="gootable"
          :model-data="TypeDocument"
          :columns="columns"
          :permissions="{
            create: $can('create', 'type_document'),
            edit: $can('edit', 'type_document'),
            show: !$can('read', 'type_document'),
            delete: $can('delete', 'type_document'),
            createImport: $can('create', 'stationbus')
          }"
          @Create="Create"
          @Edit="Edit"
          @edit-select="editSelect"
          @delete="deleteRecord"
          @delete-select-all="deleteSelectAll"
          @import-data="importData"
          @selection-changed="selectionChanged"
        />
      </b-col>

    </b-overlay>
    <vue-company-form
      v-if="modalShow"
      :modal-show="modalShow"
      :info-data="infoData"
      @close="Create"
      @create-result="$refs.gootable.loadItems()"
      @edit-result="$refs.gootable.loadItems()"
    />
    <vue-import-form
      v-if="modalImportShow"
      :info-data="null"
      :modal-show="modalImportShow"
      @import-complete="importComplete"
      @close="importComplete"
    />
  </div>
</template>
<script>
import {
  BCol, BOverlay,
} from 'bootstrap-vue'
import GoodTable from '@/components/Table/Index.vue'
import Form from '@/components/TypeDocument/Form.vue'
import FormImport from '@/components/TypeDocument/FormImport.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { columnsValue } from '@/components/TypeDocument/field/index'
import TypeDocument from '@/libs/models/TypeDocument'

export default {
  components: {
    BCol,
    BOverlay,
    GoodTable,
    'vue-company-form': Form,
    'vue-import-form': FormImport,
  },
  data() {
    return {
      rows: [],
      isLoading: false,
      modalShowArchive: false,
      show: false,
      selectRow: null,
      selecction: [],
      modalShow: false,
      infoData: {},
      searchTerm: null,
      UsersData: {},
      modalDocumentos: false,
      modalFamily: false,
      getFilePath: '',
      modalImportShow: false,
      columns: [],
      TypeDocument,
    }
  },
  async mounted() {
    this.columns = await columnsValue()
    if (this.$can('delete', 'company') || this.$can('edit', 'company')) {
      this.columns.unshift({
        label: 'Acciones',
        field: 'action',
        sortable: false,
      })
    }
  },
  methods: {
    importData() {
      this.modalImportShow = !this.modalImportShow
    },
    importComplete() {
      this.$refs.gootable.loadItems()
      this.modalImportShow = !this.modalImportShow
    },
    Create() {
      this.infoData = {}
      this.modalShow = !this.modalShow
    },
    Edit(data) {
      this.infoData = data
      this.modalShow = !this.modalShow
    },
    editSelect() {
      if (this.selecction.length > 1 || this.selecction.length === 0) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Solo puedes editar un registro',
            icon: 'XIcon',
            variant: 'warning',
            text: 'al presionar el boton editar, este solo permitira editar un registro',
          },
        },
        {
          position: 'top-center',
        })
      } else {
        this.infoData = this.selecction['0']
        this.modalShow = !this.modalShow
      }
    },
    deleteRecord(val) {
      this.$http.delete(`/v1/type-document/${val.value}`).then(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Se ha eliminado correctamente',
            icon: 'CheckCircleIcon',
            variant: 'success',
            text: 'El registro se ha eliminado.',
          },
        },
        {
          position: 'top-center',
        })
        this.$refs.gootable.loadItems()
      }).catch(error => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error',
            icon: 'XIcon',
            variant: 'danger',
            text: "Ha ocurrido un error",
          },
        },
        {
          position: 'bottom-right',
        })
      })
    },
    deleteSelectAll() {
      if (this.selecction.length > 1) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Debes seleccionar un registro',
            icon: 'XIcon',
            variant: 'warning',
            text: 'al presionar el boton eliminar debes escoger 1 registro',
          },
        },
        {
          position: 'top-center',
        })
      } else {
        this.$http.delete(`/v1/type-document/${this.selecction[0].value}`).then(res => {//eslint-disable-line
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Se han eliminado correctamente',
              icon: 'CheckCircleIcon',
              variant: 'success',
              text: 'los registros seleccionado se han eliminado.',
            },
          },
          {
            position: 'top-center',
          })
          this.$refs.gootable.loadItems()
        }).catch(error => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error',
              icon: 'XIcon',
              variant: 'danger',
              text: error.response.data.message,
            },
          },
          {
            position: 'bottom-right',
          })
        })
      }
    },
    selectionChanged(val) {
      this.selecction = val.selectedRows
    },
  },
}
</script>
