<template>
  <div>
    <b-overlay
      :show="show"
      rounded="sm"
      variant="white"
      spinner-variant="primary"
      blur="0"
      opacity=".75"
    >
      <b-tabs>
        <b-tab
          title="Compañias"
          active
        >
          <b-col cols="12">
            <good-table
              ref="gootable"
              :model-data="company"
              :columns="columns"
              :permissions="{
                create: $can('create', 'company'),
                edit: $can('edit', 'company'),
                show: !$can('read', 'company'),
                delete: $can('delete', 'company')
              }"
              @Create="Create"
              @Edit="Edit"
              @edit-select="editSelect"
              @delete="deleteRecord"
              @delete-select-all="deleteSelectAll"
              @selection-changed="selectionChanged"
            />
          </b-col>
        </b-tab>
        <b-tab
          v-if="$can('delete', 'company')"
          title="Papelera"
        >
          <b-col cols="12">
            <good-table
              ref="gootableTrashed"
              :is-active-trash="true"
              :model-data="company"
              :columns="columns"
              :permissions="{
                create: !$can('create', 'company'),
                edit: !$can('edit', 'company'),
                show: !$can('read', 'company'),
                delete: !$can('delete', 'company'),
                deleteRecovery: $can('delete', 'company')
              }"
              @Create="Create"
              @edit-select="editSelect"
              @delete-select-all="deleteSelectAll"
              @delete-recovery="deleteRecovery"
              @delete-recovery-select-all="deleteRecoverySelectAll"
              @selection-changed="selectionChanged"
            />
          </b-col>

        </b-tab>
      </b-tabs>

    </b-overlay>
    <vue-company-form
      v-if="modalShow"
      :modal-show="modalShow"
      :info-data="infoData"
      @close="Create"
      @create-result="$refs.gootable.loadItems()"
      @edit-result="$refs.gootable.loadItems()"
    />
  </div>
</template>
<script>
import {
  BCol, BOverlay, BTabs, BTab,
} from 'bootstrap-vue'
import GoodTable from '@/components/Table/Index.vue'
import Form from '@/components/Company/Form.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { columnsValue } from '@/components/Company/field/index'
import Company from '@/libs/models/Company'

export default {
  components: {
    BCol,
    BOverlay,
    BTabs,
    BTab,
    GoodTable,
    'vue-company-form': Form,
  },
  data() {
    return {
      rows: [],
      isLoading: false,
      modalShowArchive: false,
      show: false,
      selectRow: null,
      selecction: [],
      modalShow: false,
      infoData: {},
      searchTerm: null,
      UsersData: {},
      modalDocumentos: false,
      modalFamily: false,
      getFilePath: '',
      columns: [],
      company: Company,
    }
  },
  async mounted() {
    this.columns = await columnsValue()
    if (this.$can('delete', 'company') || this.$can('edit', 'company')) {
      this.columns.unshift({
        label: 'Acciones',
        field: 'action',
        sortable: false,
      })
    }
  },
  methods: {
    Create() {
      this.infoData = {}
      this.modalShow = !this.modalShow
    },
    Edit(data) {
      this.infoData = data
      this.modalShow = !this.modalShow
    },
    editSelect() {
      if (this.selecction.length > 1 || this.selecction.length === 0) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Solo puedes editar un registro',
            icon: 'XIcon',
            variant: 'warning',
            text: 'al presionar el boton editar, este solo permitira editar un registro',
          },
        },
        {
          position: 'top-center',
        })
      } else {
        this.infoData = this.selecction['0']
        this.modalShow = !this.modalShow
      }
    },
    deleteRecord(val) {
      this.$http.delete(`/v1/companies/${val.id}`).then(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Se ha eliminado correctamente',
            icon: 'CheckCircleIcon',
            variant: 'success',
            text: 'El registro se ha eliminado.',
          },
        },
        {
          position: 'top-center',
        })
        this.$refs.gootable.loadItems()
        this.$refs.gootableTrashed.loadItems()
      }).catch(error => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error',
            icon: 'XIcon',
            variant: 'danger',
            text: error.response.data.message,
          },
        },
        {
          position: 'bottom-right',
        })
      })
    },
    deleteSelectAll() {
      if (this.selecction.length === 0) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Debes seleccionar al menos un registro',
            icon: 'XIcon',
            variant: 'warning',
            text: 'al presionar el boton eliminar debes escoger al menos 1 registro',
          },
        },
        {
          position: 'top-center',
        })
      } else {
        this.$http.post('/v1/companies/many', {
          ids: this.selecction.map(element => element.id),
        }).then(res => {//eslint-disable-line
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Se han eliminado correctamente',
              icon: 'CheckCircleIcon',
              variant: 'success',
              text: 'los registros seleccionado se han eliminado.',
            },
          },
          {
            position: 'top-center',
          })
          this.$refs.gootable.loadItems()
          this.$refs.gootableTrashed.loadItems()
        }).catch(error => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error',
              icon: 'XIcon',
              variant: 'danger',
              text: error.response.data.message,
            },
          },
          {
            position: 'bottom-right',
          })
        })
      }
    },
    deleteRecovery(val) {
      this.$http.post('/v1/companies/recovery/many', {
        ids: [val.id],
      }).then(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Se ha restaurado correctamente',
            icon: 'CheckCircleIcon',
            variant: 'success',
            text: 'El registro se ha restaurado.',
          },
        },
        {
          position: 'top-center',
        })
        this.$refs.gootable.loadItems()
        this.$refs.gootableTrashed.loadItems()
      }).catch(error => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error',
            icon: 'XIcon',
            variant: 'danger',
            text: error.response.data.message,
          },
        },
        {
          position: 'bottom-right',
        })
      })
    },
    deleteRecoverySelectAll() {
      if (this.selecction.length === 0) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Debes seleccionar al menos un registro',
            icon: 'XIcon',
            variant: 'warning',
            text: 'al presionar el boton restaurar debes escoger al menos 1 registro',
          },
        },
        {
          position: 'top-center',
        })
      } else {
        this.$http.post('/v1/companies/recovery/many', {
          ids: this.selecction.map(element => element.id),
          }).then(res => {//eslint-disable-line
          this.$refs.gootable.loadItems()
          this.$refs.gootableTrashed.loadItems()
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Se ha Restaurado correctamente',
              icon: 'CheckCircleIcon',
              variant: 'success',
              text: 'El registro se ha Restaurado.',
            },
          },
          {
            position: 'top-center',
          })
        }).catch(error => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error',
              icon: 'XIcon',
              variant: 'danger',
              text: error.response.data.message,
            },
          },
          {
            position: 'bottom-right',
          })
        })
      }
    },
    selectionChanged(val) {
      this.selecction = val.selectedRows
    },
  },
}
</script>
