var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{directives:[{name:"b-modal",rawName:"v-b-modal.modal-primary",modifiers:{"modal-primary":true}}],attrs:{"cancel-variant":"outline-secondary","ok-title":"Login","no-close-on-esc":"","no-close-on-backdrop":"","centered":"","header-bg-variant":"primary","size":"lg"},scopedSlots:_vm._u([{key:"modal-header",fn:function(){return [_c('h5',{staticClass:"text-white"},[_vm._v(" "+_vm._s(_vm.title)+" ")])]},proxy:true},{key:"modal-footer",fn:function(){return [_c('div',{staticClass:"w-100"},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"float-right ml-1",attrs:{"variant":"primary"},on:{"click":function($event){return _vm.save()}}},[_vm._v(" Enviar ")]),_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"float-right",attrs:{"variant":"outline-secondary"},on:{"click":function($event){return _vm.close()}}},[_vm._v(" Cerrar ")])],1)]},proxy:true}]),model:{value:(_vm.modalShow),callback:function ($$v) {_vm.modalShow=$$v},expression:"modalShow"}},[_c('b-container',{attrs:{"fluid":""}},[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('validation-observer',{ref:"StationBusForm"},[_c('b-form',{on:{"submit":function($event){$event.preventDefault();}}},[(_vm.isNotifmsg)?_c('vue-errors',{attrs:{"notifmsg":_vm.notifmsg}}):_vm._e(),_c('b-row',[_c('b-col',{attrs:{"cols":"12","sm":"6"}},[_c('b-form-group',{attrs:{"label":"Nombre","label-for":"typecontract-name"}},[_c('validation-provider',{attrs:{"name":"Nombre","vid":"Nombre","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"StationBusForm-name","name":"StationBusForm-name","placeholder":"Nombre de la terminal","disabled":!_vm.$can('edit', 'status') || !_vm.$can('create', 'status'),"state":errors.length > 0 ? false:null},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","sm":"6"}},[_c('b-form-group',{attrs:{"label":"Compañia","label-for":"StationBusForm-status_id"}},[_c('validation-provider',{attrs:{"name":"Empresa","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"label":"name","options":_vm.optionsCompany,"loading":_vm.loadingCompany,"clearable":true,"placeholder":"Compañia a la que pertenece el terminal","disabled":!_vm.$can('edit', 'company') || !_vm.$can('create', 'company')},scopedSlots:_vm._u([{key:"no-options",fn:function(ref){return [_vm._v(" Sin datos. ")]}},{key:"option",fn:function(option){return [_c('span',{class:option.icon}),_vm._v(" "+_vm._s(option.name)+" ")]}}],null,true),model:{value:(_vm.company_id),callback:function ($$v) {_vm.company_id=$$v},expression:"company_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","sm":"6"}},[_c('b-form-group',{attrs:{"label-for":"Ciudad","label":"Ciudad"}},[_c('validation-provider',{attrs:{"name":"Ciudad","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vue-autosuggest',{ref:"autocompleteCityOrigin",attrs:{"suggestions":_vm.suggestions,"input-props":_vm.inputProps,"section-configs":_vm.sectionConfigs,"render-suggestion":_vm.renderSuggestion,"get-suggestion-value":_vm.getSuggestionValue},on:{"input":_vm.fetchResults},model:{value:(_vm.cityQuery),callback:function ($$v) {_vm.cityQuery=$$v},expression:"cityQuery"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","sm":"6"}},[_c('b-form-group',{attrs:{"label":"Terminales","label-for":"StationBusForm-status_id"}},[_c('validation-provider',{attrs:{"name":"Terminales","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"get-option-label":function (city) { return city['@attributes'].Nombre; },"options":_vm.rowHabilitadas,"loading":_vm.loading,"clearable":true,"placeholder":"Terminales habilitadas","disabled":!_vm.$can('edit', 'status') || !_vm.$can('create', 'status')},scopedSlots:_vm._u([{key:"no-options",fn:function(ref){return [_vm._v(" Sin datos. ")]}},{key:"option",fn:function(option){return [_c('span',{class:option.icon}),_vm._v(" "+_vm._s(option['@attributes'].Nombre)+" ")]}}],null,true),model:{value:(_vm.code_homolog_soap),callback:function ($$v) {_vm.code_homolog_soap=$$v},expression:"code_homolog_soap"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","sm":"6"}},[_c('b-form-group',{attrs:{"label":"Estado","label-for":"StationBusForm-status_id"}},[_c('validation-provider',{attrs:{"name":"Estado","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"state":errors.length > 0 ? false : null,"disabled":!_vm.$can('edit', 'status') || !_vm.$can('create', 'status'),"options":_vm.optionsStatus},scopedSlots:_vm._u([{key:"first",fn:function(){return [_c('b-form-select-option',{attrs:{"value":null}},[_vm._v(" Selecciona una opción ")])]},proxy:true}],null,true),model:{value:(_vm.status_id),callback:function ($$v) {_vm.status_id=$$v},expression:"status_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),(_vm.status_id != 1)?_c('b-col',{attrs:{"cols":"12","sm":"6"}},[_c('b-form-group',{attrs:{"label":"Motivo","label-for":"StationBusForm-motive"}},[_c('b-form-textarea',{attrs:{"id":"textarea-default","placeholder":"Escribe el motivo","rows":"3"},model:{value:(_vm.motive),callback:function ($$v) {_vm.motive=$$v},expression:"motive"}})],1)],1):_vm._e()],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }