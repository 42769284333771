<template>
  <div>
    <b-overlay
      :show="show"
      rounded="sm"
      variant="white"
      spinner-variant="primary"
      blur="0"
      opacity=".75"
    >
      <b-col cols="12">
        <good-table
          ref="gootable"
          :model-data="TypePayment"
          :columns="columns"
          :permissions="{
            create: $can('create', 'type_payment'),
            edit: $can('edit', 'type_payment'),
            show: !$can('read', 'type_payment'),
            delete: $can('delete', 'type_payment')
          }"
          @Create="Create"
          @Edit="Edit"
          @edit-select="editSelect"
          @delete="deleteRecord"
          @delete-select-all="deleteSelectAll"
          @selection-changed="selectionChanged"
        />
      </b-col>
    </b-overlay>
    <vue-company-form
      v-if="modalShow"
      :modal-show="modalShow"
      :info-data="infoData"
      @close="Create"
      @create-result="$refs.gootable.loadItems()"
      @edit-result="$refs.gootable.loadItems()"
    />
  </div>
</template>
<script>
import {
  BCol, BOverlay,
} from 'bootstrap-vue'
import GoodTable from '@/components/Table/Index.vue'
import Form from '@/components/TypePayment/Form.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { columnsValue } from '@/components/TypePayment/field/index'
import TypePayment from '@/libs/models/TypePayment'

export default {
  components: {
    BCol,
    BOverlay,
    GoodTable,
    'vue-company-form': Form,
  },
  data() {
    return {
      rows: [],
      isLoading: false,
      modalShowArchive: false,
      show: false,
      selectRow: null,
      selecction: [],
      modalShow: false,
      infoData: {},
      searchTerm: null,
      UsersData: {},
      modalDocumentos: false,
      modalFamily: false,
      getFilePath: '',
      columns: [],
      TypePayment,
    }
  },
  async mounted() {
    this.columns = await columnsValue()
    if (this.$can('delete', 'company') || this.$can('edit', 'company')) {
      this.columns.unshift({
        label: 'Acciones',
        field: 'action',
        sortable: false,
      })
    }
  },
  methods: {
    Create() {
      this.infoData = {}
      this.modalShow = !this.modalShow
    },
    Edit(data) {
      this.infoData = data
      this.modalShow = !this.modalShow
    },
    editSelect() {
      if (this.selecction.length > 1 || this.selecction.length === 0) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Solo puedes editar un registro',
            icon: 'XIcon',
            variant: 'warning',
            text: 'al presionar el boton editar, este solo permitira editar un registro',
          },
        },
        {
          position: 'top-center',
        })
      } else {
        this.infoData = this.selecction['0']
        this.modalShow = !this.modalShow
      }
    },
    deleteRecord(val) {
      this.$http.delete(`/v1/type-payment/${val.id}`).then(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Se ha eliminado correctamente',
            icon: 'CheckCircleIcon',
            variant: 'success',
            text: 'El registro se ha eliminado.',
          },
        },
        {
          position: 'top-center',
        })
        this.$refs.gootable.loadItems()
      }).catch(error => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error',
            icon: 'XIcon',
            variant: 'danger',
            text: error.response.data.message,
          },
        },
        {
          position: 'bottom-right',
        })
      })
    },
    deleteSelectAll() {
      if (this.selecction.length > 1) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Debes seleccionar un registro',
            icon: 'XIcon',
            variant: 'warning',
            text: 'al presionar el boton eliminar debes escoger 1 registro',
          },
        },
        {
          position: 'top-center',
        })
      } else {
        this.$http.delete(`/v1/type-payment/${this.selecction[0].id}`).then(res => {//eslint-disable-line
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Se han eliminado correctamente',
              icon: 'CheckCircleIcon',
              variant: 'success',
              text: 'los registros seleccionado se han eliminado.',
            },
          },
          {
            position: 'top-center',
          })
          this.$refs.gootable.loadItems()
        }).catch(error => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error',
              icon: 'XIcon',
              variant: 'danger',
              text: error.response.data.message,
            },
          },
          {
            position: 'bottom-right',
          })
        })
      }
    },
    deleteRecovery(val) {
      this.$http.post('/v1/companies/recovery/many', {
        ids: [val.id],
      }).then(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Se ha eliminado correctamente',
            icon: 'CheckCircleIcon',
            variant: 'success',
            text: 'El registro se ha eliminado.',
          },
        },
        {
          position: 'top-center',
        })
        this.$refs.gootable.loadItems()
        this.$refs.gootableTrashed.loadItems()
      }).catch(error => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error',
            icon: 'XIcon',
            variant: 'danger',
            text: error.response.data.message,
          },
        },
        {
          position: 'bottom-right',
        })
      })
    },
    deleteRecoverySelectAll() {
      if (this.selecction.length === 0) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Debes seleccionar al menos un registro',
            icon: 'XIcon',
            variant: 'warning',
            text: 'al presionar el boton restaurar debes escoger al menos 1 registro',
          },
        },
        {
          position: 'top-center',
        })
      } else {
        this.$http.post('/v1/companies/recovery/many', {
          ids: this.selecction.map(element => element.id),
          }).then(res => {//eslint-disable-line
          this.$refs.gootable.loadItems()
          this.$refs.gootableTrashed.loadItems()
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Se ha Restaurado correctamente',
              icon: 'CheckCircleIcon',
              variant: 'success',
              text: 'El registro se ha Restaurado.',
            },
          },
          {
            position: 'top-center',
          })
        }).catch(error => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error',
              icon: 'XIcon',
              variant: 'danger',
              text: error.response.data.message,
            },
          },
          {
            position: 'bottom-right',
          })
        })
      }
    },
    selectionChanged(val) {
      this.selecction = val.selectedRows
    },
  },
}
</script>
