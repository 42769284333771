<template>
  <div>
    <b-tabs>
      <b-tab
        title="Historial de compras"
        active
      >
        <b-col cols="12">
          <good-table
            ref="gootable"
            :model-data="ticketPurchaseHistory"
            :columns="columns"
            :permissions="{
              showChairsSelect: $can('read', 'ticket_purchase_history'),
              showTransaction: $can('read', 'payment_transaction_history'),
              showTicket: $can('read', 'ticket_purchase_history')
            }"
            :is-active-sort="true"
            :sortDefault="sortDefault"
            @show-chairs="showChairs"
            @show-chairs-select="showChairsSelect"
            @show-transaction="changeTransaction"
            @show-transaction-select="showTicketSelect"
            @show-ticket="showTicket"
            @show-ticket-select="showTicketSelect"
            @selection-changed="selectionChanged"
          />
        </b-col>
      </b-tab>
    </b-tabs>
    <vue-chairs
      v-if="showChairsSelectModal"
      :modalShow="showChairsSelectModal"
      :travelData="travelData"
      @close="closeChairs"
    />
    <vue-change-transaction
      v-if="showChangeTransactionModal"
      :modalShow="showChangeTransactionModal"
      :travelData="travelData"
      @update-table="updateTable"
      @close="closeChangeTransaction"
    />
  </div>
</template>
<script>
import {
  BCol, BTabs, BTab,
} from 'bootstrap-vue'
import GoodTable from '@/components/Table/Index.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { columnsValue } from '@/components/HistoryTravel/field/index'
import ChairsSelect from '@/components/HistoryTravel/ChairsSelect.vue'
import ChangeTransaction from '@/components/HistoryTravel/ChangeTransaction.vue'
import TicketPurchaseHistory from '@/libs/models/TicketPurchaseHistory'

export default {
  components: {
    BCol,
    BTabs,
    BTab,
    GoodTable,
    'vue-chairs': ChairsSelect,
    'vue-change-transaction': ChangeTransaction
  },
  data() {
    return {
      rows: [],
      isLoading: false,
      show: false,
      selectRow: null,
      selecction: [],
      travelData: {},
      searchTerm: null,
      UsersData: {},
      sortDefault: [
        {
          field: 'id',
          type: 'asc'
        }
      ],

      showChairsSelectModal: false,
      showChangeTransactionModal: false,
      columns: [],
      ticketPurchaseHistory: TicketPurchaseHistory,
    }
  },
  async mounted() {
    this.columns = await columnsValue()
    if (this.$can('delete', 'ticket_purchase_history') || this.$can('edit', 'ticket_purchase_history')) {
      this.columns.unshift({
        label: 'Acciones',
        field: 'action',
        sortable: false,
        width: '190px',
      })
    }
  },
  methods: {
    updateTable() {
      this.$refs.gootable.loadItems()
    },
    closeChangeTransaction() {
      this.travelData = {}
      this.showChangeTransactionModal = false
    },
    changeTransaction(value) {
      this.travelData = value
      this.showChangeTransactionModal = true
    },
    closeChairs() {
      this.travelData = {}
      this.showChairsSelectModal = false
    },
    showChairs(value) {
      this.travelData = value
      this.showChairsSelectModal = true
    },
    showChairsSelect() {
      if (this.selecction.length > 1 || this.selecction.length === 0) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Solo puedes seleccionar un registro',
            icon: 'XIcon',
            variant: 'warning',
            text: 'al presionar el boton, solo puede ejecutar la acción en un solo registro',
          },
        },
        {
          position: 'top-center',
        })
      } else {
        this.travelData = this.selecction['0']
        this.showChairsSelectModal = !this.showChairsSelectModal
      }
    },
    showTicketSelect() {
      if (this.selecction.length > 1 || this.selecction.length === 0) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Solo puedes seleccionar un registro',
            icon: 'XIcon',
            variant: 'warning',
            text: 'al presionar el boton, solo puede ejecutar la acción en un solo registro',
          },
        },
        {
          position: 'top-center',
        })
      } else {
        if(this.selecction[0].transaction.length == 0) {
          this.$toast({
          component: ToastificationContent,
            props: {
              title: 'No se puede ver el tiquete debido a que el usuario no completo la transacción',
              icon: 'XIcon',
              variant: 'warning',
            },
          },
          {
            position: 'top-center',
          })
        } else {
            const a = document.createElement('a')
            a.target = '_blank'
            a.href = `${process.env.VUE_APP_FRONTEND_WEBSITE_URL_WITHOUT_HTTP}/payments/result/${this.selecction[0].transaction[0].id_transaction}`
            a.click()
        }
      }
    },
    showTicket (value) {
      if(value.transaction.length == 0) {
          this.$toast({
          component: ToastificationContent,
            props: {
              title: 'No se puede ver el tiquete debido a que el usuario no completo la transacción',
              icon: 'XIcon',
              variant: 'warning',
            },
          },
          {
            position: 'top-center',
          })
        } else {
            const a = document.createElement('a')
            a.target = '_blank'
            a.href = `${process.env.VUE_APP_FRONTEND_WEBSITE_URL_WITHOUT_HTTP}/payments/result/${value.transaction[0].id_transaction}`
            a.click()
        }
    },
    selectionChanged(val) {
      this.selecction = val.selectedRows
    },
  },
}
</script>
