<template>
  <b-tabs
    v-model="tabIndex"
    card
    vertical
    pills
    content-class="col-12 col-md-9 mt-1 mt-md-0"
    nav-wrapper-class="col-md-3 col-12"
    nav-class="nav-left navbar-light"
  >
    <b-tab
      v-if="$can('read', 'dashboard')"
    >
      <template #title>
        <feather-icon
          icon="UserIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">Dashboard</span>
      </template>
    </b-tab>
    <b-tab
      v-if="$can('read', 'profile')"
    >
      <template #title>
        <feather-icon
          icon="UserIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">Perfil</span>
      </template>
      <vue-profile-component v-if="tabIndex == 1" />
    </b-tab>
    <b-tab
      v-if="$can('edit', 'user')"
    >
      <template #title>
        <feather-icon
          icon="LockIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">Cambiar contraseña</span>
      </template>
      <vue-password v-if="tabIndex == 2" />
    </b-tab>
    <b-tab
      v-if="$can('read', 'ticket_purchase_history') &&
        $can('edit', 'ticket_purchase_history') &&
        $can('create', 'ticket_purchase_history') &&
        $can('delete', 'ticket_purchase_history') "
    >
      <template #title>
        <feather-icon
          icon="NavigationIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">Viajes</span>
      </template>
      <vue-travel v-if="tabIndex == 3" />
    </b-tab>
    <b-tab
      v-if="$can('read', 'users') &&
        $can('edit', 'users') &&
        $can('create', 'users') &&
        $can('delete', 'users')"
    >
      <template #title>
        <feather-icon
          icon="UserIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">Usuarios</span>
      </template>
    </b-tab>
    <b-tab
      v-if="$can('read', 'company') &&
        $can('edit', 'company') &&
        $can('create', 'company') &&
        $can('delete', 'company') "
    >
      <template #title>
        <feather-icon
          icon="ServerIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">Compañias</span>
      </template>
      <vue-company v-if="tabIndex == 4" />
    </b-tab>
    <b-tab
      v-if="$can('read', 'stationbus') &&
        $can('edit', 'stationbus') &&
        $can('create', 'stationbus') &&
        $can('delete', 'stationbus')"
    >
      <template #title>
        <feather-icon
          icon="MapIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">Terminales</span>
      </template>
      <vue-bus v-if="tabIndex == 5" />
    </b-tab>
    <b-tab
      v-if="$can('read', 'type_document') &&
        $can('edit', 'type_document') &&
        $can('create', 'type_document') &&
        $can('delete', 'type_document')"
    >
      <template #title>
        <feather-icon
          icon="ArchiveIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">Tipos de documentos</span>
      </template>
      <vue-type-document v-if="tabIndex == 6" />
    </b-tab>
    <b-tab
      v-if="$can('read', 'type_payment') &&
        $can('edit', 'type_payment') &&
        $can('create', 'type_payment') &&
        $can('delete', 'type_payment')"
    >
      <template #title>
        <feather-icon
          icon="CreditCardIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">Tipos de pagos</span>
      </template>
      <vue-type-payment v-if="tabIndex == 7" />
    </b-tab>
    <b-tab
      v-if="$can('read', 'company') &&
        $can('edit', 'company') &&
        $can('create', 'company') &&
        $can('delete', 'company')"
    >
      <template #title>
        <feather-icon
          icon="FlagIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">Políticas de viajes</span>
      </template>
      <vue-policy v-if="tabIndex == 8" />
    </b-tab>
    <b-tab
      v-if="$can('read', 'payment_transaction_history')"
    >
      <template #title>
        <feather-icon
          icon="ServerIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">Historial de compras</span>
      </template>
      <vue-history-travel v-if="tabIndex == 9" />
    </b-tab>
    <b-tab
      v-if="$can('read', 'report') && false"
    >
      <template #title>
        <feather-icon
          icon="ServerIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">Reportes</span>
      </template>
    </b-tab>
    <b-tab
      v-if="$can('read', 'roles') &&
        $can('edit', 'roles') &&
        $can('create', 'roles') &&
        $can('delete', 'roles') && false"
    >
      <template #title>
        <feather-icon
          icon="UserIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">Roles y permisos</span>
      </template>
    </b-tab>
    <b-tab
      v-if="$can('read', 'config') && false"
    >
      <template #title>
        <feather-icon
          icon="ServerIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">Configuración</span>
      </template>
    </b-tab>
  </b-tabs>
</template>

<script>
import { BTabs, BTab } from 'bootstrap-vue'
import ProfileComponent from '@/components/users/Profile.vue'
import ChangePasswordComponent from '@/components/users/ChangePassword.vue'
import Company from '@/components/Company/Index.vue'
import TypeDocument from '@/components/TypeDocument/Index.vue'
import TypePayment from '@/components/TypePayment/Index.vue'
import StationBus from '@/components/StationBus/Index.vue'
import Policy from '@/components/Policy/Index.vue'
import TravelForm from '@/components/Travel/Form.vue'
import HistoryTravel from '@/components/HistoryTravel/Index.vue'

export default {
  components: {
    BTabs,
    BTab,
    'vue-profile-component': ProfileComponent,
    'vue-password': ChangePasswordComponent,
    'vue-company': Company,
    'vue-type-document': TypeDocument,
    'vue-type-payment': TypePayment,
    'vue-bus': StationBus,
    'vue-policy': Policy,
    'vue-travel': TravelForm,
    'vue-history-travel': HistoryTravel,
  },
  data() {
    return {
      options: {},
      tabIndex: 1,
    }
  },
  watch: {
    tabIndex(value) {
      localStorage.setItem('tabIndex', value)
    },
  },
  created() {
    this.settabs()
  },
  methods: {
    settabs() {
      this.tabIndex = parseInt(localStorage.getItem('tabIndex'), 10)
    },
  },
}
</script>
